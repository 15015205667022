
.s-program {
  margin-top: 160px;
  @include md {
    margin-top: 120px; }
  @include sm {
    margin-top: 80px; }
  .section__title {
    margin: 0 auto 56px;
    letter-spacing: 0.01em;
    text-align: center;
    font-size: clamp(48px, 6.8vw, 80px);
    @include black;
    line-height: .9;
    text-transform: uppercase;
    color: #00DD3B;
    background: linear-gradient(92deg, #9F0 3.45%, #00DD3B 92.28%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    @include sm {
      margin-bottom: 40px; } } }

.program {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  @include sm {
    gap: 12px; }
  &__item {
    flex: 1 1 auto;
    width: calc(33.33% - 15px);
    border-radius: 24px;
    border: 1px solid #363636;
    padding: 44px;
    background: #121413;
    min-width: 320px;
    @include tablet {
      padding: 44px 40px; }
    @include md {
      padding: 40px 34px;
      min-width: 290px; }
    @include xs {
      padding: 32px 24px; } }
  &__title {
    background: linear-gradient(92deg, #9F0 3.45%, #00DD3B 92.28%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 34px;
    font-weight: 700;
    line-height: 1;
    letter-spacing: -0.068em;
    text-transform: uppercase;
    max-width: 280px;
    text-wrap: balance;
    margin-bottom: 36px;
    @include tablet {
      font-size: 30px;
      margin-bottom: 24px; }
    @include md {
      font-size: 28px; }
    @include sm {
      font-size: 24px;
      margin-bottom: 16px; } }
  &__descr {
    font-size: 16px;
    line-height: 1.4;
    margin-bottom: 34px;
    @include tablet {
      font-size: 14px;
      margin-bottom: 24px; } }

  &__list {
    &-title {
      margin-bottom: 24px;
      font-size: 16px;
      text-transform: capitalize;
      font-weight: 600;
      @include tablet {
        margin-bottom: 16px;
        font-size: 14px; } }
    &-icon {
      img {
        display: block; } }
    &-text {
      align-self: center; }
    ul {
      display: flex;
      flex-direction: column;
      gap: 20px;
      @include tablet {
        gap: 12px; } }
    li {
      display: flex;
      align-items: flex-start;
      gap: 12px;
      font-size: 14px;
      line-height: 1.5;
      @include tablet {
        gap: 8px; }
      @include md {
        font-size: 12px; } } } }
